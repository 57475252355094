import React from 'react';
import styled from 'styled-components';

const ArticleSubHeading = ({children}) => (
  <H2>{children}</H2>
);

const H2 = styled.h2`
  font-size: 2rem;
  font-weight: 500;
  text-transform: uppercase;
  margin: 0;
  margin-bottom: 1rem;

  text-align: left;
`;

export default ArticleSubHeading;
