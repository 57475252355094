import React, {memo} from 'react';

import {Trans} from 'gatsby-plugin-react-i18next';
import {useTranslation} from "react-i18next";

import PageWrapper from '../../containers/PageWrapper';
import Section from '../../components/Section';
import ArticleHeading from '../../components/ArticleHeading';
import ArticleSubHeading from '../../components/ArticleSubHeading';
import ArticleParagraph from '../../components/ArticleParagraph';

const ModernIntranet = () => {
  const {t} = useTranslation();

  return (
    <PageWrapper
      title="Modern Intranet"
      description="Enterprises are facing seismic shifts in the way work is being done. Combat these changes by empowering your workforce to be engaged and productive, no matter where they are, with modern digital workplace solutions."
      urlPath="articles/modern-intranet"
      keywords={['coderylabs', 'modern intranets']}
    >

      <ArticleHeading><Trans>MIA Header</Trans></ArticleHeading>

      <Section>
        <ArticleSubHeading><Trans>MIA Header.1</Trans></ArticleSubHeading>
        <ArticleParagraph>
          <div dangerouslySetInnerHTML={{__html: t('MIA Paragraph.1')}}/>
        </ArticleParagraph>
      </Section>

      <Section>
        <ArticleSubHeading><Trans>MIA Header.2</Trans></ArticleSubHeading>
        <ArticleParagraph><Trans>MIA Paragraph.2</Trans></ArticleParagraph>
      </Section>

      <Section>
        <ArticleSubHeading><Trans>MIA Header.3</Trans></ArticleSubHeading>
        <ArticleParagraph><Trans>MIA Paragraph.4</Trans></ArticleParagraph>
      </Section>

      <Section>
        <ArticleSubHeading><Trans>MIA Header.4</Trans></ArticleSubHeading>
        <ArticleParagraph><Trans>MIA Paragraph.4</Trans></ArticleParagraph>
      </Section>
    </PageWrapper>
  );
}

export default memo(ModernIntranet);