import React from 'react';
import styled from 'styled-components';

const ArticleHeading = ({children}) => (
  <Header>
    <H1>{children}</H1>
    <Underline/>
  </Header>
);

const Header = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 2rem;
`;

const H1 = styled.h1`
  font-size: 3rem;
  font-weight: 500;
  text-transform: uppercase;
  margin: 0;
  margin-bottom: 1rem;

  text-align: left;
`;

const Underline = styled.div`
  width: 140px;
  border: 2px solid #30c167;
`;

export default ArticleHeading;
