import React from 'react';
import styled from 'styled-components';

const ArticleParagraph = ({style, children}) => <Container style={style}>{children}</Container>;

const Container = styled.p`
  color: #222222;
  margin: 0;
  text-align: justify;
  font-size: 1.3rem;
  font-weight: 300;
  width: 100%;

  a {
    margin-left: 0.3rem;
    color: #39b54a;
    font-weight: bold;
  }
`;

export default ArticleParagraph;
